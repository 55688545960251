import React from 'react'
import ReactDOM from 'react-dom/client'
import 'bootstrap/dist/css/bootstrap.min.css'
import './styles/index.css'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import App from './App'
import { msalConfig } from './authConfig'

/**
 * MsalProvider コンポーネントに提供される PublicClientApplication インスタンスを初期化します。
 * 再レンダリング時に再初期化されないように、ルート コンポーネントの外側でこれを初期化することをお勧めします。
 */
const msalInstance = new PublicClientApplication(msalConfig)

const root = ReactDOM.createRoot(document.getElementById('root')!)

/**
 * ほとんどまたはすべてのコンポーネントを MsalProvider コンポーネントでラップすることをお勧めします。
 * MsalProvider をルートにできるだけ近づけてレンダリングするのが最善です。
 */
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </React.StrictMode>
)
