import React from 'react'

/**
 * MS Graphから取得したユーザーに関する情報をレンダリングします。
 * @param props
 */
const ProfileData = (props: any) => {
  const { graphData } = props
  return (
    <div id="profile-div">
      <p>
        <strong>First Name: </strong>
        {graphData.givenName}
      </p>
      <p>
        <strong>Last Name: </strong>
        {graphData.surname}
      </p>
      <p>
        <strong>Email: </strong>
        {graphData.userPrincipalName}
      </p>
      <p>
        <strong>Id: </strong>
        {graphData.id}
      </p>
    </div>
  )
}

export default ProfileData
