import React, { useCallback, useState } from 'react'
import './styles/App.css'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react'
import Button from 'react-bootstrap/Button'
import PageLayout from './components/PageLayout'
import { loginRequest } from './authConfig'
import callMsGraph from './graph'
import ProfileData from './components/ProfileData'

/**
 * サインインしているユーザーに関する情報、またはユーザーに関するデータを取得するボタンを表示します。
 */
function ProfileContent() {
  const { instance, accounts } = useMsal()
  const [graphData, setGraphData] = useState(null)

  const RequestProfileData = useCallback(async() => {
    // アクセス トークンをサイレントに取得し、MS Graph データのリクエストに添付されます。
    try {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0]
      })
      const graphResponse = await callMsGraph(response.accessToken)
      setGraphData(graphResponse)
    } catch (error) {
      console.error(error)
    }
  }, [instance, accounts])

  return (
    <>
      <h5 className="card-title">
        Welcome
        {accounts[0].name}
      </h5>
      {graphData ? (
        <ProfileData graphData={graphData} />
      ) : (
        <Button variant="secondary" onClick={RequestProfileData}>
          プロフィール情報のリクエスト
        </Button>
      )}
    </>
  )
}

/**
 * ユーザーが認証されると、上記の ProfileContent コンポーネントが表示されます。それ以外の場合は、ユーザーが認証されていないことを示すメッセージが表示されます。
 */
function MainContent() {
  return (
    <div className="App">
      <AuthenticatedTemplate>
        <ProfileContent />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <h5 className="card-title">プロフィール情報を確認するにはサインインしてください。</h5>
      </UnauthenticatedTemplate>
    </div>
  )
}

export default function App() {
  return (
    <PageLayout>
      <MainContent />
    </PageLayout>
  )
}
