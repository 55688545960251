import { graphConfig } from './authConfig'

/**
 * 指定されたアクセス トークンを MS Graph API 呼び出しにアタッチします。ユーザーに関する情報を返します
 * @param accessToken
 */
async function callMsGraph(accessToken: any) {
  const headers = new Headers()
  const bearer = `Bearer ${accessToken}`

  headers.append('Authorization', bearer)

  const options = {
    method: 'GET',
    headers
  }

  return fetch(graphConfig.graphMeEndpoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error))
}

export default callMsGraph
